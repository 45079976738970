import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { OneSignal } from '@ionic-native/onesignal/ngx';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { Geolocation } from '@awesome-cordova-plugins/geolocation/ngx';
import { CallNumber } from '@awesome-cordova-plugins/call-number/ngx';
import { EmailComposer } from '@awesome-cordova-plugins/email-composer/ngx';



import { AgmCoreModule } from '@agm/core';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [BrowserModule, IonicModule.forRoot(), AppRoutingModule, HttpClientModule, FormsModule, AgmCoreModule.forRoot({
    apiKey: 'AIzaSyAOtjwhByPQCKKRfWXSrp3jGPUJpZhLCFk', libraries: ["places"]
  })],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }, OneSignal, AppVersion, Geolocation, CallNumber, EmailComposer],
  bootstrap: [AppComponent],
})
export class AppModule {}
