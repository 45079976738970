import { Component } from '@angular/core';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { CommonFunctionsService } from './common-functions.service';
import { General_Config } from './General_Config';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { RestApiService } from './rest-api.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(public restApi: RestApiService, private oneSignal: OneSignal, private router: Router, public commonFunctions: CommonFunctionsService, private platform: Platform) {
    this.platform.ready().then(() => {
      this.initializeApp();
    });
  }

  setupPush() {
    this.oneSignal.startInit('e08a0d71-3bdd-4546-80ba-4a27cc237db3', '490886393640');

    //this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert);

    this.oneSignal.handleNotificationReceived().subscribe(data => {
      // do something when notification is received
      let msg = data.payload.body;
      let title = data.payload.title;
      this.commonFunctions.presentAlertConfirm(title, msg);
      if (this.router.url == '/tabs/orders') {
        window.location.reload();
      }
    });

    this.oneSignal.handleNotificationOpened().subscribe(data => {
      // do something when a notification is opened
      //alert(JSON.stringify(data));
    });

    this.oneSignal.endInit();

    this.oneSignal.getIds().then(identity => {
      window.localStorage.setItem(General_Config.session_key + 'device_token', identity.userId);
      this.restApi.post('update_device_token', { device_token: identity.userId }).subscribe({
        next: (data: any) => {
          
        },
        error: (error: any) => {
          
        }
      });
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      if (this.platform.is('cordova')) {
        this.setupPush();
      }
    });
  }

}
