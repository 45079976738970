import { Injectable } from '@angular/core';
import { LoadingController, AlertController, ModalController, ToastController } from '@ionic/angular';
import { General_Config } from './General_Config';
import { Router } from '@angular/router';
import { Location } from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CommonFunctionsService {

  constructor(public modalController: ModalController, private location: Location, private route: Router, public alertController: AlertController, public loadingController: LoadingController, public toastController: ToastController) { }

  async presentLoading(msg) {
    const loading = await this.loadingController.create({
      message: msg,
    });
    await loading.present();
  }

  async presentToast(msg) {
    const toast = await this.toastController.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }

  dismissLoading() {
    this.loadingController.dismiss();
  }

  async presentAlertConfirm(ttl, msg) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: ttl,
      message: msg,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            console.log('Confirm Okay');
          }
        }
      ]
    });
    await alert.present();
  }

  closeDialog() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  goToPage(page) {
    this.route.navigate(['/' + page]);
  }

  goBack() {
    this.location.back();
  }

  getImageBasePath() {
    return General_Config.image_base;
  }

  getOfferText(item) {
    var text = null;
    (item.price).forEach(element => {
      if (element.code === item.selected_qty) {
        if (element.discount_amount > 0) {
          if (element.discount_type === 'fixed') {
            text = '₹' + element.discount_amount + ' off';
          }
          if (element.discount_type === 'percentage') {
            text = element.discount_amount + '% off';
          }
        }
        return false;
      }
    });
    return text;
  }

  getActualPrice(item) {
    var price = null;
    (item.price).forEach(element => {
      if (element.code === item.selected_qty) {
        if (element.discount_amount > 0) {
          price = element.amount;
        } else {
          price = null;
        }
        return false;
      }
    });
    return price;
  }

  getFinalPrice(item) {
    var price = null;
    (item.price).forEach(element => {
      if (element.code === item.selected_qty) {
        if (element.discount_amount > 0) {
          if (element.discount_type === 'fixed') {
            price = element.amount - element.discount_amount;
          }
          if (element.discount_type === 'percentage') {
            price = element.amount - ((element.amount * element.discount_amount) / 100);
          }
        } else {
          price = element.amount;
        }
        return false;
      }
    });
    return price;
  }

  getCartTotal(cart) {
    var price = null;
    var final_cart = JSON.parse(window.localStorage.getItem(General_Config.session_key + 'cart'));
    (cart).forEach(item => {
      var count = 0;
      (final_cart.items).forEach(fcart => {
        if (fcart.product_code == item.code && fcart.qty_code == item.selected_qty) {
          count = fcart.count;
          return false;
        }
      });
      (item.price).forEach(element => {
        if (element.code === item.selected_qty) {
          if (element.discount_amount > 0) {
            if (element.discount_type === 'fixed') {
              price += (element.amount - element.discount_amount) * count;
            }
            if (element.discount_type === 'percentage') {
              price += (element.amount - ((element.amount * element.discount_amount) / 100)) * count;
            }
          } else {
            price += element.amount * count;
          }
        }
      });
    });
    return price;
  }

  existInCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(General_Config.session_key + 'cart'));
    var exist = false;
    if (cart && (cart.items).length > 0) {
      (cart.items).forEach(element => {
        if (element.product_code == item.code && element.qty_code == item.selected_qty) {
          exist = true;
          return false;
        }
      });
    }
    return exist;
  }

  async addToCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(General_Config.session_key + 'cart'));
    if (cart == null) {
      cart = {};
      cart.items = [{ product_code: item.code, qty_code: item.selected_qty, count: 1 }];
    } else {
      (cart.items).push({ product_code: item.code, qty_code: item.selected_qty, count: 1 });
    }
    window.localStorage.setItem(General_Config.session_key + 'cart', JSON.stringify(cart));
  }

  increaseToCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(General_Config.session_key + 'cart'));
    if (cart != null) {
      (cart.items).forEach(element => {
        if (element.product_code == item.code && element.qty_code == item.selected_qty) {
          element.count = element.count + 1;
          return false;
        }
      });
    }
    window.localStorage.setItem(General_Config.session_key + 'cart', JSON.stringify(cart));
  }

  decreaseToCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(General_Config.session_key + 'cart'));
    if (cart != null) {
      (cart.items).forEach((element, ind) => {
        if (element.product_code == item.code && element.qty_code == item.selected_qty) {
          element.count = element.count - 1;
          if (element.count == 0) {
            (cart.items).splice(ind, 1);
          }
          return false;
        }
      });
    }
    window.localStorage.setItem(General_Config.session_key + 'cart', JSON.stringify(cart));
  }

  getCountInCart(item) {
    var cart = JSON.parse(window.localStorage.getItem(General_Config.session_key + 'cart'));
    var count = 0;
    if (cart != null) {
      (cart.items).forEach(element => {
        if (element.product_code == item.code && element.qty_code == item.selected_qty) {
          count = element.count;
          return false;
        }
      });
    }
    return count;
  }

  getCartCount() {
    var cart = JSON.parse(window.localStorage.getItem(General_Config.session_key + 'cart'));
    var count = 0;
    if (cart != null && cart.items && (cart.items).length > 0) {
      count = (cart.items).length;
    }
    return count;
  }

}
