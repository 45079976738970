import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'homelocation',
    loadChildren: () => import('./homelocation/homelocation.module').then( m => m.HomelocationPageModule)
  },
  {
    path: 'availcoupon',
    loadChildren: () => import('./availcoupon/availcoupon.module').then( m => m.AvailcouponPageModule)
  },
  {
    path: 'deliveryaddress',
    loadChildren: () => import('./deliveryaddress/deliveryaddress.module').then( m => m.DeliveryaddressPageModule)
  },
  {
    path: 'adddeliveryaddress',
    loadChildren: () => import('./adddeliveryaddress/adddeliveryaddress.module').then( m => m.AdddeliveryaddressPageModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule)
  },
  {
    path: 'contact',
    loadChildren: () => import('./contact/contact.module').then( m => m.ContactPageModule)
  },  {
    path: 'aboutus',
    loadChildren: () => import('./aboutus/aboutus.module').then( m => m.AboutusPageModule)
  },
  {
    path: 'career',
    loadChildren: () => import('./career/career.module').then( m => m.CareerPageModule)
  },

];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
