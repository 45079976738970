import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { General_Config } from './General_Config';

@Injectable({
  providedIn: 'root'
})
export class RestApiService {

  constructor(private http: HttpClient) { }

  // Http Options
  httpOptions() {
    return {
      headers: new HttpHeaders({
        'Authorization': 'jwt ' + window.localStorage.getItem(General_Config.session_key + 'token')
      })
    }
  }

  // HttpClient API put() method => DELETE
  del(path): Observable<Response> {
    return this.http.delete<Response>(General_Config.api_url + path, { headers: this.httpOptions().headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API put() method => PUT
  put(path, data): Observable<Response> {
    return this.http.put<Response>(General_Config.api_url + path, data, { headers: this.httpOptions().headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API put() method => POST
  post(path, data): Observable<Response> {
    return this.http.post<Response>(General_Config.api_url + path, data, { headers: this.httpOptions().headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // HttpClient API put() method => GET
  get(path): Observable<Response> {
    return this.http.get<Response>(General_Config.api_url + path, { headers: this.httpOptions().headers })
      .pipe(
        retry(1),
        catchError(this.handleError)
      )
  }

  // Error handling 
  handleError(error) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // Get client-side error
      errorMessage = error.error.message;
    } else {
      // Get server-side error
      errorMessage = `${error.error.message}`;
    }
    return throwError(errorMessage);
  }
}
